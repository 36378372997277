<template>
  <v-row>
    <v-col cols="12">
      <base-card>
        <div class="d-flex justify-space-between flex-wrap pa-4">
          <v-dialog v-model="dialog" tabindex="-1" persistent max-width="600px">
              <template v-slot:activator="{on, attrs}">
                
                  <v-btn
                      class="rounded-lg py-5"
                      tile
                      color="primary"
                      dark
                      v-bind="attrs"
                      v-on="on"
                  >
                      <v-icon left class="pr-2">
                          mdi-plus
                      </v-icon>
                      Add Tax Rate
                  </v-btn>
              </template>
              <v-card class="">
                  <v-card-title>
                      <span class="headline">Add Tax Rate</span>
                  </v-card-title>
                  <v-card-text>
                      <v-form ref="taxRateForm" v-model="validTaxRateForm" validation>
                        <v-container>
                            <v-row>
                                <v-col cols="12" sm="12" md="12">
                                    <v-text-field
                                        v-model="taxRateFormData.name"
                                        label="Name"
                                        dense
                                        required
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12" md="12">
                                    <v-text-field
                                        v-model="taxRateFormData.rate"
                                        label="Rate"
                                        required
                                        dense
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12" md="12">
                                    <v-text-field
                                        v-model="taxRateFormData.description"
                                        label="Description"
                                        dense
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                        </v-container>
                      </v-form>
                      <small>*indicates required field</small>
                  </v-card-text>
                  <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                          color="primary darken-1"
                          text
                          @click="dialog = false"
                          >Close</v-btn
                      >
                      <v-btn
                          color="primary darken-1"
                          text
                          :disabled="!validTaxRateForm"
                          @click="submitTaxRate"
                          >Save</v-btn
                      >
                  </v-card-actions>
              </v-card>
          </v-dialog>
          <!-- <div>
              <v-btn class="ma-2" color="primary">
                  <v-icon>mdi-cog</v-icon>
              </v-btn>
              <v-btn outlined class="ma-2">Import</v-btn>
              <v-btn outlined class="ma-2">Export</v-btn>
          </div> -->
        </div>
        <v-card-title>
          Tax Rates
          <v-spacer />

          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          />
        </v-card-title>
        <v-data-table
          v-model="selected"
          :search="search"
          :headers="headers"
          :items="taxRates"
          item-key="name"
          show-select
          class="elevation-1 table-one"
          multi-sort
        >
          <template v-slot:item.taxId="{item}">
            <div class="d-flex">
              <v-tooltip top>
                <template v-slot:activator="{on, attrs}">
                  <v-btn
                    color="success"
                    dark
                    v-bind="attrs"
                    v-on="on"
                    @click="prepareEditTaxRate(item.taxId)"
                    icon
                  >
                    <v-icon>mdi-pencil-box-outline</v-icon>
                  </v-btn>
                </template>
                <span>Edit</span>
              </v-tooltip>
            </div>
          </template>
          <template v-slot:item.issalestax="{item}">
            <template v-if="item.issalestax === true">
              <v-chip
                class=""
                color="success"
                label
                small
                text-color="white"
              >
                <v-icon
                  small
                  >mdi-check</v-icon>
              </v-chip>
            </template>
            <template v-else>
              <v-chip
                class=""
                color="danger"
                label
                small
                text-color="white"
              >
                <v-icon
                  small
                  >mdi-close</v-icon>
              </v-chip>
            </template>
          </template>
        </v-data-table>
      </base-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
  export default {
    metaInfo: {
      // title will be injected into parent titleTemplate
      title: 'Table One',
    },
    data() {
      return {
        dialog: false,
        validTaxRateForm: false,
        editTaxRate: false,
        taxRateFormData: {
          taxId: 0,
          organizationId: 0,
          name: "",
          description: "",
          rate: 0,
          isdefault: true,
          istaxexempt: true,
          sopotype: true,
          issalestax: true,
          active: true
        },
        search: '',
        selected: [],
        headers: [
          {
            text: 'Name',
            align: 'start',
            sortable: false,
            value: 'name',
          },
          { text: 'Rate %', value: 'rate' },
          { text: 'Description', value: 'description' },
          { text: 'Sales Tax', value: 'issalestax' },
          { text: 'Action', value: 'taxId' },
        ],
      }
    },
    mounted () {
      this.fetchTaxRates()
    },
    computed: {
      ...mapGetters(['loading', 'loggedInUser', 'taxRates'])
    },
    methods: {
      ...mapActions(['fetchTaxRates', 'createTaxRate', 'updateTaxRate']),
      taxById (id) {
        let tax = null
        this.taxRates.forEach(element => {
          if (element.taxId === id) {
            tax = element
          }
        });
        return tax
      },
      prepareEditTaxRate (id) {
        let tax = this.taxById(id)

        if (tax) {
          this.taxRateFormData.taxId = tax.taxId
          this.taxRateFormData.organizationId = tax.organizationId
          this.taxRateFormData.name = tax.name
          this.taxRateFormData.description = tax.description
          this.taxRateFormData.rate = tax.rate
          this.taxRateFormData.isdefault = tax.isdefault
          this.taxRateFormData.istaxexempt = tax.istaxexempt
          this.taxRateFormData.sopotype = tax.sopotype
          this.taxRateFormData.issalestax = tax.issalestax

          this.editTaxRate = true
          this.dialog = true
        }
      },
      submitTaxRate () {
        this.taxRateFormData.organizationId = this.loggedInUser.organizations[0].id
        
        if (this.editTaxRate) {
          this.updateTaxRate(this.taxRateFormData)
        }
        else {
          this.createTaxRate(this.taxRateFormData)
        }
        this.editTaxRate = false
        this.dialog = false
      }
    }
  }
</script>
<style lang="scss" scoped>
::v-deep .table-one {
    thead.v-data-table-header {
        tr {
            &:hover {
                background-color: #f2f3f8;
            }
            th {
                span {
                    font-size: 16px;
                    color: #304156;
                }
            }
        }
        tr {
            td {
                padding-bottom: 20px;
                padding-top: 20px;
            }
        }
    }
    tbody {
        tr {
            &:hover {
                background-color: #f2f3f8 !important;
            }
        }
    }
}
</style>
